import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function ProductCardSkeleton() {
  return (
    <Card sx={{ width:{xs:'41.8vw', lg:'250px'}, height:{xs:'60vw', lg:'350px'}, boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", borderRadius:2, border:"solid white 3px" }}>
      <Skeleton sx={{ height:{xs:'37.5vw', lg:"215px"} }} animation="wave" variant="rectangular" />
      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
          <Skeleton animation="wave" height={20} width="80%" />
        </React.Fragment>
      </CardContent>
      <CardActions>
        <React.Fragment>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{px:1}}>
            <Skeleton animation="wave" height={35} width={120} />
            <Stack spacing={0.5} direction="row" justifyContent="center" alignItems="center" sx={{pl:5.5}}>
              <Skeleton animation="wave" variant="circular" width={25} height={25} />
              <Skeleton animation="wave" variant="circular" width={25} height={25} />
            </Stack>
          </Stack>
        </React.Fragment>
      </CardActions>
    </Card>
  );
}
