import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function SupplierCardSkeleton() {
  return (
    <Card sx={{ width:{xs:'41.8vw', lg:'250px'}, /*height:{xs:'55vw', lg:'300px'},*/ boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", borderRadius:2, border:"solid white 3px" }}>
      <CardContent>
        <React.Fragment>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{px:0.5}}>
            <Skeleton animation="wave" height={20} width={110} />
            <Stack spacing={0.5} direction="row" justifyContent="center" alignItems="center" sx={{pl:5.5}}>
              <Skeleton animation="wave" height={20} width={70} />
            </Stack>
          </Stack>
          <Skeleton animation="wave" height={20} width={70} sx={{ml:0.5}}/>
        </React.Fragment>
      </CardContent>
      <Skeleton sx={{ height:{xs:'41.8vw', lg:'250px'} }} animation="wave" variant="rectangular" />
    </Card>
  );
}
