//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/BecomeSupplierPage.css';
//import Banner from '../Banner';
//import SideBar from '../SideBar';
import Footer from '../Footer';
//import NotificationData from '../JSON/NotificationData';
import CountriesDropdown from '../LeoFarataFrameWorks/CountriesDropdown';
import { SuccessAccountCreationSnackbarContext } from '../Contexts';
import {auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, db, doc, setDoc, getDoc, serverTimestamp} from '../Firebase/firebase';
import CountriesData from '../JSON/CountriesData';
import {useState, /*useEffect,*/ useRef, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios';

export default function BecomeSupplierView(){

  const navigateTo = useNavigate();

  const selectedItem = useRef('');
  const [loginErrorLog, setLoginErrorLog] = useState('');
  const [signupErrorLog, setSignupErrorLog] = useState('');
  const [typeDeConnexion, setTypeDeConnexion] = useState('connexion');
  const [loading, setLoading] = useState(false);

  const [accountType, setAccountType] = useState("Fournisseur");

  const [successAccountCreationSnackbarOpened, setSuccessAccountCreationSnackbarOpened] = useContext(SuccessAccountCreationSnackbarContext);

  const changerTypeDeConnexion = () => {
    setTypeDeConnexion(typeDeConnexion => typeDeConnexion === 'connexion' ? 'inscription' : 'connexion');
    setLoginErrorLog(loginErrorLog => "");
    setSignupErrorLog(signupErrorLog => "");
  }

  const inscription = async (event) => {
   setLoading(loading => true);
   /*if(event.target.password.value !== event.target.confirmpassword.value){
     setSignupErrorLog(signupErrorLog => "Les mots de passe ne correspondent pas.");
     setLoading(loading => false);
     return;
   }*/

   const dataBaseUsername = await axios.get(`https://aqso-json-server-xnrb.onrender.com/SuppliersKeywords?username_like=${event.target.username.value}`);

   if(dataBaseUsername.data.length !== 0){
     setSignupErrorLog(signupErrorLog => "Le nom d'utilisateur ou d'entreprise existe déjà.");
     setLoading(loading => false);
     return;
   }

   let userId = "";

   await createUserWithEmailAndPassword(auth, event.target.email.value, event.target.password.value)
   .then(async (credentials) => {
      userId = credentials.user.uid;
      return await setDoc(doc(db, "users", credentials.user.uid), {
        userId: credentials.user.uid,
        email: event.target.email.value,
        phone: event.target.phone?.value ?? null,
        //password: event.target.password.value,
        username: event.target.username.value,
        description: event.target.description?.value ?? null,
        companyId: event.target.companyId?.value ?? null,
        accounttype: event.target.accounttype.value,
        firstname: event.target.firstname.value,
        name: event.target.name.value,
        dateofbirth: event.target.dateofbirth?.value ?? null,
        country: event.target.country.value,
        city: event.target.city?.value ?? null,
        address: event.target.address?.value ?? null,
        function: event.target.function?.value ?? null,
        industrialization: event.target.industrialization?.value ?? null,
        legalstatus: event.target.legalstatus?.value ?? null,
        rating: 0,
        isVerified: false,
        profilPicture: "https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/users%2Fphoto_de_profil_par_defaut.jpg?alt=media&token=f142bd79-0fe2-4f0c-a16c-bae4a84a6932",
        createdAt: serverTimestamp()
     })
   })
   .then(async () => {
     return await axios.post(`https://aqso-json-server-xnrb.onrender.com/SuppliersKeywords`, {id: userId, username: event.target.username.value});
   })
   .then(() => {
     setSignupErrorLog(signupErrorLog => "");
     setSuccessAccountCreationSnackbarOpened(successAccountCreationSnackbarOpened => true);
     //navigateTo("/ConnexionInscription");
     window.open("/ConnexionInscription", "_Self");
     /*if(event.target.accounttype.value === "Acheteur"){
       navigateTo("/ConnexionInscription");
     }
     else{
       navigateTo("/Profil");
     }*/
   })
   .catch((err) => {
     //auth/invalid-password
     //auth/invalid-email
     //auth/invalid-email-verified
     //auth/invalid-uid
     //auth/invalid-phone-number
     //auth/user-not-found
     let errorMassage = "";
     switch(err.code){
       case "auth/internal-error":
        errorMassage = "Le serveur d'authentification a rencontré une erreur inattendue.";
        break;
       case "auth/email-already-in-use":
        errorMassage = "L'email fournie est déjà utilisée par un autre utilisateur.";
        break;
       case "auth/email-already-exists":
        errorMassage = "L'email fournie est déjà utilisée par un autre utilisateur.";
        break;
       case "auth/weak-password":
        errorMassage = "Le mot de passe doit contenir au moins 6 caractères.";
        break;
       default:
        errorMassage = "Il y'a des champs mal ou non renseignés.";
     }
     setSignupErrorLog(signupErrorLog => errorMassage);
     setLoading(loading => false);
     //console.log(err)
   });
  }

  return (
    <div className='page-container'>

      <div className='page-body'>
        {/*<Banner backgroundImage="/public_assets/Banner_Background_Image_1.png" title={typeDeConnexion} subtitle={typeDeConnexion === 'connexion' ? "Sur cette page vous pouvez vous connecter à votre compte." : "Sur cette page vous pouvez créer votre compte."} labelWidth="100%"/>*/}
        <div className='page-content'>
          <div className="become-supplier-container">
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant="h4" sx={{ textTransform: 'capitalize', color:'#FF6A00', textAlign:'center'}}>Devenir fournisseur</Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize', color:'#FF6A00', textAlign:'center'}}>Rejoignez la référence du commerce international en B2B et faites croitre votre entreprise !</Typography>
            </Stack>

            <Stack spacing={6} direction={{xs:"column", lg:"row"}} justifyContent="center" alignItems="center">
              <Stack spacing={4} sx={{maxWidth:{xs:350, lg:600}}}>
                <Typography variant="body1" sx={{ textTransform: 'capitalize', color:'gray', textAlign:{xs:'center', lg:'justify'}, paddingLeft:{xs:'0px', lg:'93px'}, fontWeight:'bold'}}>Rejoindre AQSO c’est :</Typography>
                <Stack spacing={4} direction={{xs:"column", lg:"row"}} justifyContent="center" alignItems="center">
                  <img className="text-icon" src="/public_assets/Extra_Icons/icone_donner_argent.png" alt="Text Icon"/>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize', color:'gray', textAlign:'justify'}}>Accéder à un marché international et booster vos ventes : En rejoignant AQSO, vous exposez vos produits à des entreprises internationales, vous ouvrant ainsi de nouvelles opportunités d'exportation et d'expansion.</Typography>
                </Stack>
                <Stack spacing={4} direction={{xs:"column", lg:"row"}} justifyContent="center" alignItems="center">
                  <img className="text-icon" src="/public_assets/Extra_Icons/icone_echange.png" alt="Text Icon"/>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize', color:'gray', textAlign:'justify'}}>Simplifier vos transactions : Grâce à AQSO, bénéficiez d'une plat forme dédiée qui facilite les échanges directs avec vos clients sans intermédiaires, garantissant des transactions transparentes et sécu isées.</Typography>
                </Stack>
                <Stack spacing={4} direction={{xs:"column", lg:"row"}} justifyContent="center" alignItems="center">
                  <img className="text-icon" src="/public_assets/Extra_Icons/icone_360_degres.png" alt="Text Icon"/>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize', color:'gray', textAlign:'justify'}}>Accéder à un outil de vente 360° : qui permetta aux fournisseurs de gérer leurs ventes, analyser leurs performances commerciales grâce à des tableaux de bord intuitifs et optimiser les recommandations de produits via des technologies d'intelligence artificielle.</Typography>
                </Stack>
              </Stack>

              <div className='become-supplier-form-box'>
                <div className='become-supplier-panel'>
                  <Typography variant="h4" sx={{ textTransform: 'capitalize', color:'#FF6A00', fontWeight:'bold', pb:2}}>Inscription</Typography>

                  <form onSubmit={(event) => {event.preventDefault(); inscription(event);}}>

                    <Stack spacing={3}>
                      <Stack spacing={3} sx={{py:0.6}}>
                        <FormControl required sx={{px:2}}>
                          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" id="accounttype" name="accounttype" defaultValue="Fournisseur" sx={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}} onChange={(event) => setAccountType(accountType => event.target.value)}>
                            <FormControlLabel value="Acheteur" control={<Radio sx={{"&.Mui-checked":{color:'#FF6A00'}}}/>} label="Devenir acheteur" sx={{"& .MuiFormControlLabel-label":{fontSize:'16.5px'}}}/>
                            <FormControlLabel value="Fournisseur" control={<Radio sx={{"&.Mui-checked":{color:'#FF6A00'}}}/>} label="Devenir fournisseur" sx={{"& .MuiFormControlLabel-label":{fontSize:'16.5px'}}}/>
                          </RadioGroup>
                        </FormControl>

                        <Stack direction={{xs:"column", lg:"row"}} spacing={{xs:3, lg:1.5}}>
                          <TextField fullWidth sx={{backgroundColor:'#fcf7f2', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="firstname" name="firstname" label="Prénom" placeholder="Prénom" required/>
                          <TextField fullWidth sx={{backgroundColor:'#fcf7f2', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="name" name="name" label="Nom" placeholder="Nom" required/>
                        </Stack>

                        {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker sx={{backgroundColor:'#fcf7f2', '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} slotProps={{textField: {required: true, id:"dateofbirth", name:"dateofbirth"}}} label="Date de naissance" required/>
                        </LocalizationProvider>*/}

                        <Stack direction={{xs:"column", lg:"row"}} spacing={{xs:3, lg:1.5}}>
                          <TextField fullWidth sx={{backgroundColor:'#fcf7f2', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="username" name="username" label="Nom d'utilisateur / Entreprise" placeholder="Nom d'utilisateur / Entreprise" required/>
                          <FormControl fullWidth placeholder="Pays" required>
                            <InputLabel id="countrylabel" sx={{'&.Mui-focused':{color:'black'}}}>Pays</InputLabel>
                            <Select defaultValue="" sx={{backgroundColor:'#fcf7f2', borderRadius:2, textAlign:'left', '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="countrylabel" id="country" name="country" label="Pays">
                              {CountriesData.map((item, index) => <MenuItem key={index} value={item.name}>{item.name}</MenuItem>)}
                            </Select>
                          </FormControl>
                        </Stack>

                        <Stack direction={{xs:"column", lg:"row"}} spacing={{xs:3, lg:1.5}}>
                          <TextField fullWidth sx={{backgroundColor:'#fcf7f2', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="email" id="email" name="email" label="Email" placeholder="Email" required/>
                          <TextField fullWidth sx={{backgroundColor:'#fcf7f2', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="password" id="password" name="password" label="Mot de passe" placeholder="*********" required/>
                          {/*<TextField fullWidth sx={{backgroundColor:'#fcf7f2', '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="password" id="confirmpassword" name="confirmpassword" label="Confirmez le mot de passe" placeholder="*********" required/>*/}
                        </Stack>

                        {accountType === "Fournisseur" &&
                         <>
                          <TextField fullWidth sx={{backgroundColor:'#fcf7f2', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="companyId" name="companyId" label="Identifiant de l'entreprise" placeholder="Identifiant de l'entreprise" required/>
                          <TextField fullWidth multiline rows={4} /*maxRows={4}*/ sx={{backgroundColor:'#fcf7f2', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="description" name="description" label="Description de l'entreprise" placeholder="Description de l'entreprise" /*required*//>
                         </>
                        }

                        {/*<FormControl fullWidth placeholder="Type de compte" required>
                          <InputLabel id="accounttypelabel" sx={{'&.Mui-focused':{color:'black'}}}>Type de compte</InputLabel>
                          <Select sx={{backgroundColor:'#fcf7f2', textAlign:'left', '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="accounttypelabel" id="accounttype" name="accounttype" label="Type de compte">
                            <MenuItem value={"Acheteur"}>Acheteur</MenuItem>
                            <MenuItem value={"Fournisseur"}>Fournisseur</MenuItem>
                          </Select>
                        </FormControl>*/}

                        {/*<TextField fullWidth sx={{backgroundColor:'#fcf7f2', '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="address" name="address" label="Addresse" placeholder="Addresse" required/>

                        <Stack direction="row" spacing={1.5}>
                          <FormControl fullWidth placeholder="Industrialisation" required>
                            <InputLabel id="industrializationlabel" sx={{'&.Mui-focused':{color:'black'}}}>Industrialisation</InputLabel>
                            <Select sx={{backgroundColor:'#fcf7f2', textAlign:'left', '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="industrializationlabel" id="industrialization" name="industrialization" label="Industrialisation">
                              <MenuItem value={"Semi-Industrialisation"}>Semi-Industrialisation</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl fullWidth placeholder="Statut juridique" required>
                            <InputLabel id="legalstatuslabel" sx={{'&.Mui-focused':{color:'black'}}}>Statut juridique</InputLabel>
                            <Select sx={{backgroundColor:'#fcf7f2', textAlign:'left', '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="legalstatuslabel" id="legalstatus" name="legalstatus" label="Statut juridique">
                              <MenuItem value={"SARL"}>SARL</MenuItem>
                              <MenuItem value={"SASU"}>SASU</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>*/}
                      </Stack>

                      <LoadingButton
                        sx={{color:"white", fontSize:20, padding:1, textTransform: 'none', fontWeight:'bold', borderRadius:2, backgroundColor:'#FF6A00', "&:hover":{backgroundColor:'orange'}}}
                        loadingIndicator={<CircularProgress color="inherit" size={32}/>}
                        loading={loading}
                        /*loadingPosition="end"*/
                        variant="contained"
                        type="submit"
                      >
                        <span style={{marginTop:3.5}}>S'inscrire</span>
                      </LoadingButton>

                      {signupErrorLog !== '' && <Alert severity="error">{signupErrorLog}</Alert>}
                    </Stack>
                  </form>
                  {/*<span></span>*/}
                </div>
              </div>
            </Stack>

          </div>
         </div>
         <Footer/>
      </div>

    </div>
  )
}
