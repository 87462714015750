//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/EditProductPage.css';
import Banner from '../Banner';
//import SideBar from '../SideBar';
import Footer from '../Footer';
//import NotificationData from '../JSON/NotificationData';
import LFModalView from '../LeoFarataFrameWorks/LFModalView';
import { ProductUpdatedSnackbarContext } from '../Contexts';
//import NotificationCard from '../LeoFarataFrameWorks/NotificationCard';
import {useState, /*useEffect,*/ useRef, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Rating from '@mui/material/Rating';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CancelRoundedIcon from '@mui/icons-material/Cancel';
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import {auth, db, doc, updateDoc, storage, ref, uploadBytes, getDownloadURL, serverTimestamp, deleteObject} from '../Firebase/firebase';
import axios from 'axios';

export default function EditProductView(){

  const location = useLocation();
  const oldProductInfos = location.state;
  const [productInfos, setProductInfos] = useState(location.state);

  const [productUpdatedSnackbarOpened, setProductUpdatedSnackbarOpened] = useContext(ProductUpdatedSnackbarContext);

  const navigateTo = useNavigate();

  const [artworkModalVisible, setArtworkModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const [editProductErrorLog, setEditProductErrorLog] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductInfos({ ...productInfos, [name]: value });
  };

  const handleImageUpload = (event) => {
    const newImages = [...productInfos.images];
    const file = event.target.files[0];
    //console.log(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        newImages.push(e.target.result);
        setProductInfos({ ...productInfos, ['images']: newImages });
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (imageURL, index, productId, imageImageUrls) => {
    const media = await fetch(imageURL);
    const bytes = await media.blob();
    await uploadBytes(ref(storage, `products/${productId}/image-${index+1}-${(new Date()).toJSON()}.jpg`), bytes)
    .then(async (snapshot) => {
      return await getDownloadURL(ref(storage, snapshot.ref))
    })
    .then((url) => {imageImageUrls.push(url); console.log("Image")})
    .catch((err) => console.log(err))
  }


  // Mise à jour du produit ==========================================
  const deleteImage = async (productId, index) => {
    await deleteObject(ref(storage, `products/${productId}/image${index+1}.jpg`))
    .then(() => null)
    .catch((err) => null)
  }

  const updateProduct = async (event) => {
    setLoading(loading => true);
    event.preventDefault();

    if(productInfos.images.length === 0){
      setEditProductErrorLog(editProductErrorLog => "Veuillez ajouter au moins une image de votre produit.");
      setLoading(loading => false);
      return;
    }

    const imagesToDelete = oldProductInfos.images.filter((image, index) => !productInfos.images.includes(image));
    const imagesToUpload = productInfos.images.filter((image, index) => !oldProductInfos.images.includes(image));
    const imagesWithNoChanges = oldProductInfos.images.filter((image, index) => productInfos.images.includes(image));

    let imageImageUrls = [];

    /*// Test Console ============================
    console.log(imagesToDelete);
    console.log(imagesToUpload);
    console.log(imagesWithNoChanges);*/

    let promiseFunctions = [];

    if(imagesToDelete.length !== 0){
      imagesToDelete.map((_, index) => promiseFunctions.push(deleteImage(productInfos.productId, index)));
    }

    if(imagesToUpload.length !== 0){
      imagesToUpload.map((imageURL, index) => promiseFunctions.push(uploadImage(imageURL, index, productInfos.productId, imageImageUrls)));
    }

    //Promise.all(imagesToDelete.images.map((_, index) => deleteImage(productInfos.productId, index)))

    await Promise.all(promiseFunctions)
    .then(async () => {
      return await updateDoc(doc(db, "products", productInfos.productId), {
        /*productId: productInfos.productId,
        supplierId: productInfos.supplierId,*/
        productName: event.target.productName.value,
        description: event.target.description.value,
        composition: event.target.composition.value,
        minUnitPrice: parseInt(event.target.minUnitPrice.value),
        maxUnitPrice: parseInt(event.target.maxUnitPrice?.value) ?? null,
        nutritionalBenefits: event.target.nutritionalBenefits.value,
        use: event.target.use.value,
        minOrderQuantity: parseInt(event.target.minOrderQuantity.value),
        category: event.target.category.value,
        productType: event.target.productType.value,
        manufacturingType: event.target.manufacturingType.value,
        /*country: "Niger",*/
        certification: event.target.certification.value,
        images: imagesWithNoChanges.concat(imageImageUrls),
        /*createdAt: serverTimestamp(),*/
        updatedAt: serverTimestamp()
      })
    })
    .then(async () => {
      return await axios.put(`https://aqso-json-server-xnrb.onrender.com/ProductsKeywords/${productInfos.productId}`, {id: productInfos.productId, productName: productInfos.productName, supplierId: productInfos.supplierId, category: productInfos.category, country: productInfos.country, productType: productInfos.productType, manufacturingType: productInfos.manufacturingType, minUnitPrice: parseInt(event.target.minUnitPrice.value), maxUnitPrice: parseInt(event.target.maxUnitPrice.value)/*, createdAt: productInfos.createdAt.toDate()*/});
    })
    .then(() => {
      setLoading(loading => false);
      setProductUpdatedSnackbarOpened(productUpdatedSnackbarOpened => true);
      navigateTo(-1);
      //oldProductInfos = productInfos;
    })
    .catch((err) => {setLoading(loading => false); console.log(err)})
  };

  return (
    <div className='page-container'>

      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Fournisseurs/fournisseur1.png" title="Editer produit" subtitle="Sur cette page, vous pouvez éditer un de vos produits mise en ligne sur AQSO." labelWidth="100%"/>
        <div className='page-content'>
          <div className="edit-product-container">

          <form style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}} onSubmit={updateProduct}>
            <Stack spacing={4} sx={{display:'flex', justifyContent:'center', alignItems:'center', width:{xs:'90vw', lg:'100%'}, boxSizing:'border-box'}}>
              <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width:'100%', padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                 <Stack sx={{width:'100%'}} spacing={3}>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <Typography variant="h6" sx={{textAlign:'center'}}>Détails</Typography>
                  </Stack>
                  <Divider />
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <TextField fullWidth defaultValue={productInfos.productName} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="productName" name="productName" label="Nom du produit" placeholder="Nom du produit" required/>
                  </Stack>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <TextField fullWidth defaultValue={productInfos.description} onChange={handleChange} multiline rows={4} /*maxRows={4}*/ sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="description" name="description" label="Description" placeholder="Description" required/>
                  </Stack>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <TextField fullWidth defaultValue={productInfos.composition} onChange={handleChange} multiline rows={4} /*maxRows={4}*/ sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="composition" name="composition" label="Composition" placeholder="Composition" required/>
                  </Stack>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <TextField fullWidth defaultValue={productInfos.nutritionalBenefits} onChange={handleChange} multiline rows={4} /*maxRows={4}*/ sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="nutritionalBenefits" name="nutritionalBenefits" label="Avantages nutritionnels" placeholder="Avantages nutritionnels" required/>
                  </Stack>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <TextField fullWidth defaultValue={productInfos.use} onChange={handleChange} multiline rows={4} /*maxRows={4}*/ sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="use" name="use" label="Utilisation" placeholder="Utilisation" required/>
                  </Stack>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <TextField fullWidth defaultValue={productInfos.minOrderQuantity} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="number" id="minOrderQuantity" name="minOrderQuantity" label="Quantité minimale de commande" placeholder="Quantité minimale de commande" required/>
                  </Stack>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <FormControl fullWidth placeholder="Certification" required>
                      <InputLabel id="certificationlabel" sx={{'&.Mui-focused':{color:'black'}}}>Certification</InputLabel>
                      <Select fullWidth defaultValue={productInfos.certification} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="certificationlabel" id="certification" name="certification" label="Certification">
                        <MenuItem value={"Aucune"}>Aucune</MenuItem>
                        <MenuItem value={"Bio"}>Bio</MenuItem>
                        <MenuItem value={"Vegan"}>Vegan</MenuItem>
                        <MenuItem value={"Sans gluten"}>Sans gluten</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth placeholder="Catégorie" required>
                      <InputLabel id="categorylabel" sx={{'&.Mui-focused':{color:'black'}}}>Catégorie</InputLabel>
                      <Select fullWidth defaultValue={productInfos.category} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="categorylabel" id="category" name="category" label="Catégorie">
                        <MenuItem value={"Matières premières"}>Matières premières</MenuItem>
                        <MenuItem value={"Produits dérivés"}>Produits dérivés</MenuItem>
                        <MenuItem value={"Autres"}>Autres</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                    <FormControl fullWidth placeholder="Type de produit" required>
                      <InputLabel id="productTypelabel" sx={{'&.Mui-focused':{color:'black'}}}>Type de produit</InputLabel>
                      <Select fullWidth defaultValue={productInfos.productType} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="productTypelabel" id="productType" name="productType" label="Type de produit">
                        <MenuItem value={"Prêt à envoyer"}>Prêt à envoyer</MenuItem>
                        <MenuItem value={"Personnalisable"}>Personnalisable</MenuItem>
                        <MenuItem value={"Echantillons possible"}>Echantillons possible</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth placeholder="Type de production" required>
                      <InputLabel id="manufacturingTypelabel" sx={{'&.Mui-focused':{color:'black'}}}>Type de production</InputLabel>
                      <Select fullWidth defaultValue={productInfos.manufacturingType} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="manufacturingTypelabel" id="manufacturingType" name="manufacturingType" label="Type de production">
                        <MenuItem value={"Artisanal"}>Artisanal</MenuItem>
                        <MenuItem value={"Semi-industriel"}>Semi-industriel</MenuItem>
                        <MenuItem value={"Industriel"}>Industriel</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                 </Stack>
                </Paper>
              </div>

              <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width:'100%', padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                  <Stack sx={{width:'100%'}} spacing={3}>
                   <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                     <Typography variant="h6" sx={{textAlign:'center'}}>Prix</Typography>
                   </Stack>
                   <Divider />
                   <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                     <TextField fullWidth defaultValue={productInfos.minUnitPrice} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="number" id="minUnitPrice" name="minUnitPrice" label="Prix unitaire minimal" placeholder="Prix unitaire minimal" required InputProps={{endAdornment:<InputAdornment position="end">FCFA</InputAdornment>}}/>
                   </Stack>
                   <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                     <TextField fullWidth defaultValue={productInfos.maxUnitPrice} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="number" id="maxUnitPrice" name="maxUnitPrice" label="Prix unitaire maximal" placeholder="Prix unitaire maximal" /*required*/ InputProps={{endAdornment:<InputAdornment position="end">FCFA</InputAdornment>}}/>
                   </Stack>
                  </Stack>
                </Paper>
              </div>

              <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width:'100%', padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                  <Stack sx={{width:'100%'}} spacing={3}>
                   <Stack sx={{width:'100%'}} direction={{xs:'column', lg:'row'}} spacing={{xs:3, lg:1.5}}>
                     <Typography variant="h6" sx={{textAlign:'center'}}>Images du produit</Typography>
                   </Stack>
                   <Divider />
                   <Stack spacing={2}>
                     <Button size="small" sx={{ color:'rgba(0,0,0,0)', "&:hover":{backgroundColor:"rgba(0,0,0,0)"}, "&.active":{backgroundColor:"rgba(0,0,0,0)"}}} onClick={(event) => {if(productInfos.images.length < 5){document.getElementById('productImages').click()}}} onChange={handleImageUpload}>
                       <input type="file" id='productImages' style={{ display:'none' }}/>
                       <Stack spacing={0.5} sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', border:"1px lightgray dashed", borderRadius:3, paddingTop:{xs:10, lg:20}, paddingBottom:{xs:10, lg:20}, backgroundColor:"#f5f9ff", '&:hover':{backgroundColor:productInfos.images.length < 5 ? 'rgba(0,255,0,0.1)' : 'rgba(255,0,0,0.1)'}}}>
                        <AddPhotoAlternateIcon sx={{color:'gray', width:50, height:50}}/>
                        <Typography variant="body3" sx={{textAlign:'center', color:'gray', textTransform:'none'}}>{productInfos.images.length < 5 ? "Ajouter une image" : "Vous ne pouvez plus ajouter une image"}</Typography>
                       </Stack>
                     </Button>

                     <Stack direction="row" spacing={2}>
                       {productInfos.images.map((imagePreview, index) => (
                         <Tooltip key={index} arrow title={`image ${index + 1}`}>
                           <Badge badgeContent={<IconButton size="small" onClick={() => {setProductInfos({ ...productInfos, ['images']: productInfos.images.filter((_, indexFilter) => indexFilter !== index) })}}><CancelRoundedIcon sx={{color:'#b00000', backgroundColor:'white', borderRadius:20, width:20, height:20}}/></IconButton>}>
                             <img style={{width:65, height:65, border:'0.5px solid gray', borderRadius:5}} src={imagePreview} alt="product image"/>
                           </Badge>
                         </Tooltip>
                       ))}
                     </Stack>
                   </Stack>
                  </Stack>
                </Paper>
              </div>

              {editProductErrorLog !== '' && <Alert severity="error">{editProductErrorLog}</Alert>}

              <Stack sx={{display:'flex', width:'100%', justifyContent:'flex-end'}} direction='row' spacing={{xs:3, lg:1.5}}>
                <LoadingButton
                  disabled={_.isEqual(oldProductInfos, productInfos)}
                  sx={{color:"white", textTransform: 'none', fontWeight:'bold', width:150, borderRadius:2}}
                  loadingIndicator={<CircularProgress color="inherit" size={32}/>}
                  loading={loading}
                  /*loadingPosition="end"*/
                  variant="contained"
                  type="submit"
                >
                  <span style={{marginTop:3.5}}>Enregistrer</span>
                </LoadingButton>
              </Stack>
            </Stack>

          </form>
          </div>
         </div>
         <Footer/>
      </div>

    </div>
  )
}
