//import menu_icone from '../assets/icone_menu.png';
import '../Styles/SideBar.css';
import MainDropdown from "./SideBar_Components/SideBarComponents";
import {useState} from "react";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import _ from 'lodash';
import CountriesData from './JSON/CountriesData';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function SideBar({rootClassName, defaultFilter, oldFilter, filter, handleFilter, applyFiltersAndSearch}){

 const [isPrixOpen, setPrixOpen] = useState(true);
 const [isCategorieOpen, setCategorieOpen] = useState(true);
 const [isPaysOpen, setPaysOpen] = useState(true);
 const [isTypeProduitOpen, setTypeProduitOpen] = useState(true);
 const [isTypeDeProductionOpen, setTypeDeProductionOpen] = useState(true);

 const [showPriceButton, setShowPriceButton] = useState(false);


 const toggleDropdown = (setDropdownState) => {
   setDropdownState(prevState => !prevState);
 };

  return (
    <div className="sidebar">
      <IconButton size="small" sx={{ display:{xs:'flex', lg:'none'}, marginLeft:'13.5rem', alignSelf:'flex-end', color: 'text.primary' }} onClick={() => {document.getElementsByClassName("sidebar")[0].classList.remove("sidebar-visible")}}>
        <CloseIcon sx={{color:'black', width:35, height:35}}/>
      </IconButton>
      <List sx={{ display:'flex', flexDirection:'column', gap:0.5, width: '100%', maxWidth: 250, bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={() => toggleDropdown(setPrixOpen)} sx={{px:1, backgroundColor:'#012d6e', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#001430'}}}>
          <Typography variant="body1" sx={{fontSize:'16px', fontWeight:'bold', color:'white'}}>Prix</Typography>
          {isPrixOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/*<Divider/>*/}
        <Collapse in={isPrixOpen} timeout="auto" unmountOnExit>
          <List component="div">
            <Box sx={{px:1}}>
              <Slider value={filter.price} min={5} step={100} max={5000} sx={{color:'#0053CF'}} onChange={(e) => {handleFilter("price", `${e.target.value}`); setShowPriceButton(showPriceButton => true)}}/>
              <Typography id="non-linear-slider" gutterBottom>
                {filter.price} FCFA
              </Typography>
              {showPriceButton && <ListItemButton onClick={() => {setShowPriceButton(showPriceButton => false); applyFiltersAndSearch()}} sx={{px:1, backgroundColor:'#ad4902', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#632a01'}}}>
                <Typography variant="body1" sx={{fontSize:'16px', fontWeight:'bold', color:'white'}}>Appliquer</Typography>
                <CheckCircleIcon/>
              </ListItemButton>}
            </Box>
          </List>
        </Collapse>

        <ListItemButton onClick={() => toggleDropdown(setCategorieOpen)} sx={{px:1, backgroundColor:'#012d6e', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#001430'}}}>
          <Typography variant="body1" sx={{fontSize:'16px', fontWeight:'bold', color:'white'}}>Catégorie</Typography>
          {isCategorieOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/*<Divider/>*/}
        <Collapse in={isCategorieOpen} timeout="auto" unmountOnExit>
          <List component="div">
            <Box sx={{px:1}}>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.categories.includes("Matières premières")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.categories.includes("Matières premières") ? filter.categories.splice(filter.categories.indexOf("Matières premières"), 1) : filter.categories.push("Matières premières");
                  handleFilter("categories", filter.categories);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Matières premières"
              />
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.categories.includes("Produits dérivés")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.categories.includes("Produits dérivés") ? filter.categories.splice(filter.categories.indexOf("Produits dérivés"), 1) : filter.categories.push("Produits dérivés");
                  handleFilter("categories", filter.categories);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Produits dérivés"
              />
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.categories.includes("Autres")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.categories.includes("Autres") ? filter.categories.splice(filter.categories.indexOf("Autres"), 1) : filter.categories.push("Autres");
                  handleFilter("categories", filter.categories);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Autres"
              />
            </FormGroup>
            </Box>
          </List>
        </Collapse>

        <ListItemButton onClick={() => toggleDropdown(setPaysOpen)} sx={{px:1, backgroundColor:'#012d6e', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#001430'}}}>
          <Typography variant="body1" sx={{fontSize:'16px', fontWeight:'bold', color:'white'}}>Pays</Typography>
          {isPaysOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/*<Divider/>*/}
        <Collapse in={isPaysOpen} timeout="auto" unmountOnExit>
          <List component="div">
            <Box sx={{px:1}}>
              <FormControl fullWidth placeholder="Pays">
                <InputLabel id="countrylabel" sx={{'&.Mui-focused':{color:'black'}}}>Pays</InputLabel>
                <Select defaultValue={defaultFilter.country} sx={{/*px:1,*/ backgroundColor:'#fff0e6', height:'40px', textAlign:'left', '&.MuiOutlinedInput-root':{borderRadius:"5px", '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="countrylabel" id="country" name="country" label="Pays" onChange={(e) => {
                  handleFilter("country", e.target.value);
                  setShowPriceButton(showPriceButton => false);
                }}>
                  <MenuItem key={-1} value={"Aucun"}>Aucun</MenuItem>
                  {CountriesData.map((item, index) => <MenuItem key={index} value={item.name}>{item.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Box>
          </List>
        </Collapse>

        <ListItemButton onClick={() => toggleDropdown(setTypeProduitOpen)} sx={{px:1, backgroundColor:'#012d6e', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#001430'}}}>
          <Typography variant="body1" sx={{fontSize:'16px', fontWeight:'bold', color:'white'}}>Type produit</Typography>
          {isTypeProduitOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/*<Divider/>*/}
        <Collapse in={isTypeProduitOpen} timeout="auto" unmountOnExit>
          <List component="div">
            <Box sx={{px:1}}>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.productTypes.includes("Prêt à envoyer")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.productTypes.includes("Prêt à envoyer") ? filter.productTypes.splice(filter.productTypes.indexOf("Prêt à envoyer"), 1) : filter.productTypes.push("Prêt à envoyer");
                  handleFilter("productTypes", filter.productTypes);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Prêt à envoyer"
              />
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.productTypes.includes("Personnalisable")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.productTypes.includes("Personnalisable") ? filter.productTypes.splice(filter.productTypes.indexOf("Personnalisable"), 1) : filter.productTypes.push("Personnalisable");
                  handleFilter("productTypes", filter.productTypes);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Personnalisable"
              />
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.productTypes.includes("Echantillons possible")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.productTypes.includes("Echantillons possible") ? filter.productTypes.splice(filter.productTypes.indexOf("Echantillons possible"), 1) : filter.productTypes.push("Echantillons possible");
                  handleFilter("productTypes", filter.productTypes);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Echantillons possible"
              />
            </FormGroup>
            </Box>
          </List>
        </Collapse>

        <ListItemButton onClick={() => toggleDropdown(setTypeDeProductionOpen)} sx={{px:1, backgroundColor:'#012d6e', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#001430'}}}>
          <Typography variant="body1" sx={{fontSize:'16px', fontWeight:'bold', color:'white'}}>Type de production</Typography>
          {isTypeDeProductionOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {/*<Divider/>*/}
        <Collapse in={isTypeDeProductionOpen} timeout="auto" unmountOnExit>
          <List component="div">
            <Box sx={{px:1}}>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.manufacturingTypes.includes("Artisanal")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.manufacturingTypes.includes("Artisanal") ? filter.manufacturingTypes.splice(filter.manufacturingTypes.indexOf("Artisanal"), 1) : filter.manufacturingTypes.push("Artisanal");
                  handleFilter("manufacturingTypes", filter.manufacturingTypes);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Artisanal"
              />
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.manufacturingTypes.includes("Semi-industriel")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.manufacturingTypes.includes("Semi-industriel") ? filter.manufacturingTypes.splice(filter.manufacturingTypes.indexOf("Semi-industriel"), 1) : filter.manufacturingTypes.push("Semi-industriel");
                  handleFilter("manufacturingTypes", filter.manufacturingTypes);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Semi-industriel"
              />
              <FormControlLabel control={<Checkbox defaultChecked={defaultFilter.manufacturingTypes.includes("Industriel")} sx={{"&.MuiCheckbox-colorPrimary":{color:'#0053CF'}}}
                onChange={(e) => {
                  filter.manufacturingTypes.includes("Industriel") ? filter.manufacturingTypes.splice(filter.manufacturingTypes.indexOf("Industriel"), 1) : filter.manufacturingTypes.push("Industriel");
                  handleFilter("manufacturingTypes", filter.manufacturingTypes);
                  setShowPriceButton(showPriceButton => false);
                }}/>}
                label="Industriel"
              />
            </FormGroup>
            </Box>
          </List>
        </Collapse>

        {/*<ListItemButton disabled={_.isEqual(oldFilter, filter)} onClick={() => applyFiltersAndSearch()} sx={{px:1, backgroundColor:'#ad4902', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#632a01'}}}>
          <Typography variant="body1" sx={{fontSize:'16px', fontWeight:'bold', color:'white'}}>Rechercher</Typography>
          <SearchRoundedIcon/>
        </ListItemButton>*/}
      </List>
    </div>
  )
}
