// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, reauthenticateWithCredential, EmailAuthProvider, onAuthStateChanged, signOut, updateEmail, updatePassword , deleteUser} from "firebase/auth";
import { getFirestore, serverTimestamp, doc, addDoc, setDoc, query, where, orderBy, limit, startAfter, startAt, collection, increment, getDoc, getDocs, updateDoc, onSnapshot, deleteDoc} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
////import firebase from "@react-native-firebase/app";
////import messaging from "@react-native-firebase/messaging";
//import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyApfyDuHwQrHb7TXaxhp8vq6iNAUdbNSAI",

  authDomain: "aqso-e1f9b.firebaseapp.com",

  projectId: "aqso-e1f9b",

  storageBucket: "aqso-e1f9b.appspot.com",

  messagingSenderId: "477907481730",

  appId: "1:477907481730:web:e13ac7e68e845a6f83709b",

  measurementId: "G-1YBY2L7DP7"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const auth = getAuth(app);

const db = getFirestore(app);

const storage = getStorage(app);

const functions = getFunctions(app);

//const messaging = getMessaging(app);
//firebase.initializeApp(firebaseConfig);
//messaging().requestPermission();

export{
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  deleteUser,
  sendPasswordResetEmail,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signOut,
  updateEmail,
  updatePassword,
  db,
  serverTimestamp,
  doc,
  increment,
  addDoc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  startAt,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  onSnapshot,
  deleteDoc,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  functions,
  httpsCallable,
  //messaging,
  }
